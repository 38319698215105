html {
    scroll-behavior: smooth;
    font-size: 13.33333333vw;
}

body {
    padding: 0;
    margin: 0;
}
body,
html {
    background-color: #fff;
    font-family:
            Google Sans,
            Roboto,
            Arial,
            sans-serif;
}
::-webkit-scrollbar {
    display: none;
}

#app {
    max-width: 750px;
    margin: 0 auto;
}

#pwaDownload {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

 * {
     border: 0;
     box-sizing: border-box;
     margin: 0;
     padding: 0;
 }

 a{
     text-decoration: none;
 }

.topbar {
    height: 54px;
}

.topbar {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 0 13px 0 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
}

.topbar .topbar__left {
    height: 37px;
    width: 166px;
}

.topbar .topbar__right {
    height: 36px;
    width: 114px;
}

.topbar.active {
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.3);
}

.container {
    padding: 54px 22px 0;
    width: 100%;
}

.container.container--landing-page .install-btn__install,
.container:not(.container--landing-page)[data-type='INSTALL'] .install-btn__ing,
.container:not(.container--landing-page)[data-type='LANDING_PAGE'] .install-btn__install {
    display: flex;
}

.container:not(.container--landing-page)[data-type='INSTALLING'] .install-btn {
    opacity: 0.4;
}

.container:not(.container--landing-page)[data-type='INSTALLING'] .install-btn__ing {
    display: flex;
}

.container:not(.container--landing-page)[data-type='INSTALLING'] .header__icon--img {
    height: 34px;
    width: 34px;
}

.container:not(.container--landing-page)[data-type='INSTALLING'] .header__icon--loading {
    opacity: 1;
    visibility: visible;
}

.container:not(.container--landing-page)[data-type='PLAY'] .install-btn__play {
    display: flex;
}

.container:not(.container--landing-page)[data-type='INSTALLING'] .header__info-description,
.container:not(.container--landing-page)[data-type='PLAY'] .header__info-description {
    display: none;
}

.container:not(.container--landing-page)[data-type='INSTALLING'] .header__info-progress,
.container:not(.container--landing-page)[data-type='PLAY'] .header__info-progress {
    display: block;
}

.header {
    display: flex;
    margin-bottom: 28px;
}

.header__icon {
    align-items: center;
    display: flex;
    height: 74px;
    justify-content: center;
    margin-right: 22px;
    position: relative;
    width: 74px;
}

.header__icon--img {
    border-radius: 20%;
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
    transition: all 0.35s;
    width: 100%;

    &.installing {
        transform: scale(0.5);
    }
}

.header__icon--loading {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 5;

    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.header__icon--loading svg {
    animation: loading-rotate 2s linear infinite;
    color: #028760;
    display: block;
    height: 100%;
    width: 100%;
}

.header__icon--loading svg circle {
    stroke: currentColor;
    stroke-width: 2px;
    stroke-linecap: round;
    animation: loading-circular 1.5s ease-in-out infinite;
}

.header__info {
    flex: 1;
    overflow: hidden;
}

.header__info-title {
    align-items: center;
    display: flex;
    margin-bottom: 2px;
    overflow-wrap: anywhere;
    word-break: keep-all;
}

.header__info-title h1 {
    color: #111;
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
}

.header__info-title-tag-wrap {
    align-items: center;
    display: inline-flex;
    height: 31px;
    width: 20px;
}

.header__info-title-tag {
    height: 20px;
    margin-left: 3px;
    vertical-align: baseline;
    width: 20px;
}

.header__info-description {
    color: #028760;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 5px;
}

.header__info-progress {
    color: #333;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 3px;
}

.header__info-play {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 10px;
    font-weight: 400;
    height: 14px;
}

.header__info-play .ic_dun {
    margin-right: 4px;
    width: 10px;
}

.info {
    display: flex;
    margin-bottom: 38px;
}

.info__view {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.info__view:before {
    background: #ebebeb;
    bottom: 0;
    content: '';
    height: 24px;
    left: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 1px;
}

.info__view:first-child:before {
    display: none;
}

.info__view .ic_x {
    display: block;
    margin-left: 2px;
    width: 11px;
}

.info__view-value {
    color: #111;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}

.info__view-sui,
.info__view-value {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
}

.info__view-sui {
    border: 1px solid #020202;
    border-radius: 2px;
    color: #020202;
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    font-size: 12px;
    font-weight: 700;
    height: 16px;
    justify-content: center;
    line-height: 14px;
    min-width: 17px;
    padding: 0 2px;
}

.info__view-label {
    color: #606569;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
}

.install-btn {
    background: #028760;
    border-radius: 8px;
    margin-bottom: 28px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.install-btn__view {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    height: 35px;
    /*line-height: 20px;*/
    width: 100%;
    text-align: center;
    line-height: 35px;
}

.install-btn__ing,
.install-btn__view {
    align-items: center;
    /*display: none;*/
    justify-content: center;
}

.install-btn__ing {
    flex-direction: column;
    padding: 8px;
}

.install-btn__ing__rapid {
    align-items: center;
    color: #ffe336;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    justify-content: center;
    line-height: 21px;
}

.install-btn__ing__rapid img {
    display: block;
    margin-right: 4px;
    width: 12px;
}

.install-btn__ing__countdown {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
}

.share-row {
    margin-bottom: 28px;
}

.share-row,
.share-row__item {
    align-items: center;
    display: flex;
    justify-content: center;
}

.share-row__item {
    margin-right: 27px;
}

.share-row__item > img {
    height: 20px;
    margin-right: 10px;
    width: 17px;
}

.share-row__item > div {
    color: #00a173;
    font-size: 14px;
    font-weight: 500;
}

.img-scroll {
    margin-bottom: 24px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

.img-scroll::-webkit-scrollbar {
    display: none;
}

.img-scroll__list {
    display: flex;
    flex-wrap: nowrap;
    height: 224px;
    width: fit-content;
}

.img-scroll__view {
    border-radius: 8px;
    height: 224px;
    margin-right: 11px;
    overflow: hidden;
}

.img-scroll__view img {
    display: block;
    height: 100%;
    width: auto;
}

.description {
    position: relative;
}

.description__right-arrow {
    height: 15px;
    position: absolute;
    right: 0;
    top: 4px;
    width: 15px;
}

.description__title {
    color: #202124;
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
}

.description__content {
    color: #666;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 19px;
    margin-bottom: 30px;
    overflow-wrap: break-word;
    white-space: break-spaces;
}

.description__label {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 15px;
}

.description__label-item {
    align-items: center;
    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 15px;
    color: #5f6368;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    justify-content: center;
    margin: 0 11px 16px 0;
    padding: 0 12px;
    width: fit-content;
}

.description__data-safety {
    border: 1px solid #dadce0;
    border-radius: 7px;
    padding: 24px 26px 8px;
    width: 100%;
}

.description__data-safety__item {
    display: flex;
    margin-bottom: 16px;
}

.description__data-safety__item .item-icon {
    align-items: center;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 22px;
    width: 15px;
}

.description__data-safety__item .item-icon img {
    display: block;
}

.description__data-safety__item .item-content {
    flex: 1;
    overflow: hidden;
    word-break: break-all;
}

.description__data-safety__item .item-content p {
    color: #444;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
}

.shiny-btn:after {
    animation: shiny-btn-anim 5s ease-in-out infinite;
    background-color: #fff;
    content: '';
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: -180px;
    width: 30px;
}

@keyframes shiny-btn-anim {
    0% {
        opacity: 0;
        transform: scale(0) rotate(45deg);
    }

    80% {
        opacity: 0.5;
        transform: scale(0) rotate(45deg);
    }

    81% {
        opacity: 1;
        transform: scale(4) rotate(45deg);
    }

    to {
        opacity: 0;
        transform: scale(50) rotate(45deg);
    }
}

.footer__section {
    padding-top: 15px;
}

.footer__section-title {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    padding-top: 14px;
}

.footer__section-item {
    color: #606468;
    font-size: 14px;
    padding-top: 14px;
}

.footer__bottom {
    color: #606468;
    font-size: 13px;
    margin-top: 53px;
}

.footer__bottom:last-of-type {
    margin-top: 33px;
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.mr-22 {
    margin-right: 22px;
}

.line-clamp-6 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
}

.menus,
.menus-placeholder {
    height: 86px;
    position: fixed;
    bottom: 0;
    left: 0;
}

.menus {
    background-color: #fff;
    border-top: 1px solid #dadce0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    left: 0;
    padding-top: 8px;
    position: fixed;
    right: 0;
}

.menus .menus__item {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.menus .menus__item > img {
    height: 20px;
    width: 21px;
}

.menus .menus__item > .title {
    color: #5f6368;
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    font-size: 13px;
    font-weight: 500;
    margin-top: 4px;
}

.menus .menus__item > .title.active {
    color: #028760;
}

#rb-layer {
    display: block !important;
}

#rb-layer img {
    max-height: none;
    max-width: none;
}

#rb-layer .animation-dialog {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    opacity: 0;
    visibility: hidden;
}

#rb-layer .animation-dialog.dialog-visible {
    animation-name: rb-fade-in;
}

#rb-layer .animation-dialog.dialog-hidden {
    animation-duration: 0.15s;
    animation-name: rb-fade-out;
}

#rb-layer .rb-mask {
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

#rb-layer .rb-mask p {
    display: none;
    font-size: 0;
}

#rb-layer .rb-mask-show {
    animation-name: rb-fade-in;
}

#rb-layer .rb-mask-hide {
    animation-name: rb-fade-out;
}

#rb-layer .rb-toast {
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    left: 50%;
    line-height: 36px;
    max-width: 90%;
    min-width: 48px;
    opacity: 0;
    padding: 0 10px;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    white-space: pre;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 1000;
}

#rb-layer .rb-toast-show {
    animation-name: rb-fade-in;
}

#rb-layer .rb-toast-hide {
    animation-name: rb-fade-out;
}

#rb-layer .rb-toast span {
    color: #fff;
    font-size: 16px;
}

#rb-layer .rb-loading {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    left: 0;
    line-height: 20px;
    margin: 0 auto;
    max-height: 100%;
    max-width: 70%;
    min-height: 100px;
    opacity: 0;
    overflow: hidden;
    padding: 16px;
    position: fixed;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    visibility: hidden;
    white-space: pre-wrap;
    width: 100px;
    z-index: 1005;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}

#rb-layer .rb-loading--show {
    opacity: 1;
    visibility: visible;
}

#rb-layer .rb-loading__text {
    min-height: 0;
    min-width: 100px;
    padding: 8px 12px;
    width: fit-content;
}

#rb-layer .rb-loading__icon {
    animation: loading-rotate 2s linear infinite;
    display: flex;
    height: 30px;
    margin-bottom: 8px;
    width: 30px;
}

#rb-layer .rb-loading__icon svg {
    color: #fff;
    display: block;
    height: 100%;
    width: 100%;
}

#rb-layer .rb-loading__icon svg circle {
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    animation: loading-circular 1.5s ease-in-out infinite;
}

#rb-layer .rb-install-now-dialog {
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    padding: 32px 24px 24px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    width: 325px;
    z-index: 1000;
}

#rb-layer .rb-install-now-dialog[data-type='ACTIVED'] .install-now__actived,
#rb-layer .rb-install-now-dialog[data-type='LOADING'] .install-now__loading {
    display: flex;
}

#rb-layer .rb-install-now-dialog .install-now__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 37px;
}

#rb-layer .rb-install-now-dialog .install-now__header-title {
    align-items: center;
    display: flex;
    margin-bottom: 8px;
}

#rb-layer .rb-install-now-dialog .install-now__header-title img {
    margin-right: 7px;
    width: 28px;
}

#rb-layer .rb-install-now-dialog .install-now__header-title span {
    color: #666;
    font-size: 27px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 33px;
}

#rb-layer .rb-install-now-dialog .install-now__header-msg {
    align-items: center;
    border: 1px solid #747475;
    border-radius: 3px;
    color: #666;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    height: 25px;
    padding: 0 10px;
}

#rb-layer .rb-install-now-dialog .install-now__main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 108px;
    justify-content: center;
    width: 100%;
}

#rb-layer .rb-install-now-dialog .install-now__loading {
    align-items: center;
    color: #028760;
    display: none;
    height: 100px;
    justify-content: center;
    position: relative;
    width: 100px;
}

#rb-layer .rb-install-now-dialog .install-now__loading svg {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

#rb-layer .rb-install-now-dialog .install-now__loading svg circle {
    stroke: currentColor;
    stroke-width: 3;
    stroke-linecap: round;
    animation: loading-circular 1.5s ease-in-out infinite;
}

#rb-layer .rb-install-now-dialog .install-now__loading span {
    font-size: 16px;
    font-weight: 600;
}

#rb-layer .rb-install-now-dialog .install-now__actived {
    align-items: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

#rb-layer .rb-install-now-dialog .install-now__actived-tag {
    align-items: center;
    background: rgba(2, 135, 96, 0.1);
    border: 1px solid #028760;
    border-radius: 14px;
    display: flex;
    height: 28px;
    justify-content: center;
    margin-bottom: 34px;
    padding: 0 11px;
}

#rb-layer .rb-install-now-dialog .install-now__actived-tag img {
    margin-right: 5px;
    width: 15px;
}

#rb-layer .rb-install-now-dialog .install-now__actived-tag span {
    color: #028760;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

#rb-layer .rb-install-now-dialog .install-now__actived-btn {
    align-items: center;
    background: #028760;
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    height: 45px;
    justify-content: center;
    width: 100%;
}

#rb-layer .rb-install-dialog {
    background-color: #fff;
    border-radius: 18px;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    padding: 24px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    width: 353px;
    z-index: 1000;
}

#rb-layer .rb-install-dialog .install__title {
    color: #111;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 28px;
}

#rb-layer .rb-install-dialog .install__main {
    align-items: center;
    display: flex;
    margin-bottom: 32px;
    width: 100%;
}

#rb-layer .rb-install-dialog .install__main--icon {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    height: 37px;
    margin-right: 12px;
    width: 37px;
}

#rb-layer .rb-install-dialog .install__main--title {
    color: #111;
    font-size: 23px;
    font-weight: 400;
    line-height: 27px;
}

#rb-layer .rb-install-dialog .install__main--url {
    color: #111;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
}

#rb-layer .rb-install-dialog .install__foot {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    width: 100%;
}

#rb-layer .rb-install-dialog .install__foot div {
    color: #3f80ed;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    width: 94px;
}

#rb-layer .rb-back-dialog {
    align-items: center;
    background-color: #fff;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    padding: 25px 20px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    width: 85%;
    z-index: 1000;
    transition: opacity 0.3s;

    &.show {
        visibility: visible;
        opacity: 1;
    }
}

#rb-layer .rb-back-dialog .back__logo {
    width: 166px;
}

#rb-layer .rb-back-dialog .back__title {
    font-size: 17px;
    line-height: 24px;
    margin-top: 32px;
    text-align: center;
}

#rb-layer .rb-back-dialog .back__mark {
    color: #028760;
    font-weight: 500;
}

#rb-layer .rb-back-dialog .back__block {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 100%;
}

#rb-layer .rb-back-dialog .back__icon {
    border-radius: 20%;
    height: 70px;
    margin-right: 20px;
    width: 70px;
}

#rb-layer .rb-back-dialog .back__info-title {
    align-items: center;
    display: flex;
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    font-size: 22px;
    font-weight: 500;
    overflow-wrap: anywhere;
    word-break: keep-all;
}

#rb-layer .rb-back-dialog .back__info-title img {
    height: 20px;
    margin-left: 5px;
    width: 20px;
}

#rb-layer .rb-back-dialog .back__info-description {
    color: #028760;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
}

#rb-layer .rb-back-dialog .back__info-play {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: 14px;
}

#rb-layer .rb-back-dialog .back__info-play .ic_dun {
    margin-right: 4px;
    width: 10px;
}

#rb-layer .rb-back-dialog .back__detail {
    align-items: center;
    display: flex;
    font-size: 14px;
}

#rb-layer .rb-back-dialog .back__detail p {
    line-height: 18px;
    margin: 10px 0;
}

#rb-layer .rb-back-dialog .back__detail img {
    width: 89px;
}

#rb-layer .rb-back-dialog .back__btn {
    align-items: center;
    background-color: #028760;
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 35px;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}

@keyframes rb-fade-in {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes rb-fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes loading-rotate {
    0% {
        transform: rotate(0);
    }

    to {
        transform: rotate(1turn);
    }
}

@keyframes loading-circular {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -40;
    }

    to {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -120;
    }
}

.comments {
    font-family:
            Google Sans Bold,
            Roboto,
            Arial,
            sans-serif;
    margin-top: 34px;
}

.comments__title {
    color: #222;
    font-size: 19px;
    font-weight: 500;
}

.comments__tips {
    color: #444;
    font-size: 13px;
    margin: 20px 0 10px;
}

.comments__type {
    display: flex;
    flex-flow: wrap;
    margin: 40px 0 20px;
}

.comments__type-item {
    align-items: center;
    border: 1px solid #dadce0;
    border-radius: 15.5px;
    color: #5f6368;
    display: flex;
    font-size: 13px;
    height: 31px;
    justify-content: center;
    margin: 0 10px 10px 0;
    padding: 0 15px;
    width: -moz-fit-content;
    width: fit-content;
}

.comments__type-item img {
    margin-right: 10px;
    width: 13px;
}

.comments__type-active {
    background-color: #e6f3ef;
    border: 1px solid #e6f3ef;
    color: #028760;
}

.comments__scoring {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.comments__scoring-points {
    font-size: 58px;
    font-weight: 400;
}

.comments__scoring-icon {
    color: #444;
    font-size: 24px;
    font-weight: 500;
}

.comments__scoring-star {
    display: flex;
}

.comments__scoring-people {
    color: #444;
    font-size: 12px;
    margin-top: 13px;
}

.comments__scoring-content {
    flex: 1;
    margin-left: 10px;
    max-width: 180px;
}

.comments__scoring-item {
    align-items: center;
    color: #444;
    display: flex;
    font-size: 11px;
    margin-bottom: 6.5px;
}

.comments__br {
    background-color: #dadce0;
    height: 0.5px;
    margin: 40px 0 10px;
    width: 100%;
}

.comments__list-item {
    margin: 30px 0;
}

.comments__list-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}

.comments__list-avatar {
    border-radius: 50%;
    height: 32px;
    margin-right: 16px;
    -o-object-fit: fill;
    object-fit: fill;
    width: 32px;
}

.comments__list-name {
    color: #222;
    font-size: 14.5px;
    margin-right: auto;
}

.comments__list-icon {
    height: 16px;
    width: 4px;
}

.comments__list-stars span {
    color: #444;
    font-size: 12px;
    margin-left: 8px;
}

.comments__list-detail {
    color: #444;
    font-size: 14px;
    margin-top: 10px;
    overflow-wrap: break-word;
    white-space: break-spaces;
}

.progress {
    background-color: #e3e3e3;
    border-radius: 5px;
    flex: 1;
    height: 9px;
    margin-left: 14.5px;
    max-width: 166px;
    overflow: hidden;
    width: 100%;
}

.progress-bar {
    border-radius: 5px;
    height: 100%;
}

.pwa-download {
    height: 100vh !important;
}

.footer {
    font-size: 13px;
    color: #444;
    padding-bottom: 60px;
}